import React, { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, Modal, TextField } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppHeader from './appHeader/AppHeader';
import styles from './SignDetails.module.css';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { getPDFDownload } from '../Api/PDFDownloadApi';
import { toast } from 'react-toastify';
import { toggleLoader } from '../store/loader/loaderReducer';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteModal } from './DeleteModal';
import axios from 'axios';
import DocumentViewModal from './DocumentViewModal';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';

const os_type = ['Mac On', 'Linux On', 'Windows On', 'Android On', 'IOS On'];
const browser_type = ['Chrome', 'Firefox', 'Edge', 'Opera', 'Safari'];

const approval_status = [
  { status: 'Pending Approval', color: '#F72222' },
  { status: 'Approved', color: '#23AB94' },
  { status: 'Rejected', color: '#F72222' },
]

const SignDetails = ({
  values,
  setValues,
  signStatus,
  onDelete,
  setShow,
  keys,
  type,
  fetchSignForOthers,
  fetchSignMySelf,
  handleApproval
}) => {
  const id = setValues.Id;
  const statusId = setValues['Status'];

  const [toggleSignJourney, setToggleSignJourney] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [open, setOpen] = useState(false);
  const [edit_btn, setEdit_btn] = useState(false);
  const [approved, setApproved] = useState(false);
  const [reject, setReject] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();

  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');

  const handleDownLoad = () => {
    let data = { sign_id: setValues?.Id, Org_Id: setValues?.Org_Id, type: type, Template_Name: setValues?.Template_Name }
    setLoading(true);
    dispatch(toggleLoader(true));
    getPDFDownload(data, (res) => {
      let data = new Blob([res?.data], { type: "application/pdf" });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = setValues?.Template_Name?.split('.')[0] + '.pdf';
      link.click();
      setLoading(false);
      dispatch(toggleLoader(false));
    }, (err) => {
      toast.error(err.message);
      setLoading(true);
      dispatch(toggleLoader(false));
    });
  };

  useEffect(() => {
    let data = setValues?.approvers_info?.find((s) => s.Approver_User_Id === show_integeration?.user_information?.Id);
    switch (data?.Status) {
      case 0:
        setDisabled(true);
        break;
      case 1:
        setApproved(true);
        setDisabled(true);
        break;
      case 2:
        setReject(true);
        setDisabled(true);
        break;
      default:
        break;
    }
  }, []);

  const handleView = (file, org) => {
    dispatch(toggleLoader(true));
    let data = { sign_id: file, Org_Id: org, type: type }
    getPDFDownload(data, (res) => {
      setFile(res.data);
      dispatch(toggleLoader(false));
    }, (e) => {
      toast.error(e.message);
      dispatch(toggleLoader(false));
    });
  };

  const DateFormat = (unix) => {
    let getDate = moment(new Date(unix));
    let startOfToday = moment().startOf('day');
    let startOfDate = moment(getDate).startOf('day');
    let daysDiff = startOfDate.diff(startOfToday, 'days');
    let days = {
      0: 'Today',
      '-1': 'Yesterday',
      1: 'Tomorrow',
    };

    if (Math.abs(daysDiff) <= 1) {
      let day = days[daysDiff];
      let time = moment(new Date(unix)).format('LT');
      return day + ' ' + time;
    } else {
      return moment(new Date(unix)).format('DD/MM/YYYY LT');
    }
  };

  const SignDetails = (key) => {
    switch (key.value) {
      case 'Template_Name':
        return <Box>
          {open ?
            <RenameDocument setOpen={setOpen} setEdit_btn={setEdit_btn} setValues={setValues} fetchSignForOthers={fetchSignForOthers} setShow={setShow} type={type} fetchSignMySelf={fetchSignMySelf} /> :
            <Box className={styles.document_rename_input} onMouseEnter={() => (admin || ((type === 2 && show_integeration?.user_permission[3]?.update === 1) || (type === 1 && show_integeration?.user_permission[4]?.update === 1))) && setEdit_btn(true)} onMouseLeave={() => setEdit_btn(false)}>
              <Box>{setValues[key.value]}</Box>
              {edit_btn &&
                <IconButton
                  className={styles.edit_btn}
                  onClick={() => setOpen(true)} sx={{ marginLeft: '25px !important' }}>
                  <CreateIcon sx={{ fontSize: 15 }} />
                </IconButton>}
            </Box>}
        </Box>
      case 'Sign_Journey':
        return setValues[key.value] && Object.keys(JSON.parse(setValues[key.value]))?.length ? journeyEl : '-';
      case 'request_type':
        return 'Direct';
      case 'Status':
        const statusEl = (
          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Box
              className={styles.custom_dot}
              style={{ backgroundColor: signStatus[statusId]?.color }}
            ></Box>
            {signStatus[statusId]?.status}
          </Box>
        );
        return statusEl;
      case 'approvers_info':
        return <Box>{setValues[key.value] ?
          setValues[key.value]?.map((item) => {
            return <Box key={item?.Email_Id} sx={{ lineHeight: '20px' }}>{item?.Email_Id}</Box>
          }) : '-'}
        </Box>

      default:
        return setValues[key.value] ? setValues[key.value] : '-';
    }
  };

  const journeyEl = (

    <Box
      onClick={() => setToggleSignJourney(true)}
      style={{
        color: 'var(--primary)',
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
    >
      Click to View
    </Box>
  );

  const getJourneyLength = (obj) => {
    if (!obj?.journey) {
      let len = 0;
      Object.keys(obj).forEach(key => {
        if (obj[key]?.journey) len = obj[key]?.journey?.length + len;
        else len = JSON.parse(obj[key])?.journey?.length + len;
      });
      return len;
    }
  };

  let journey_len = getJourneyLength(setValues?.Sign_Journey ? JSON.parse(setValues?.Sign_Journey) : {});
  return (
    <Box className={styles.main_wrap}>
      <AppHeader moduleText='Detailed Sign Report' />

      <Box className={styles.edit}>
        <Button
          onClick={() => setShow(false)}
          variant='outlined'
          className={styles.back_button}
          style={{ textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
        >
          <ArrowBackIcon
            style={{ width: '15px', height: '15px', marginRight: '5px' }}
          />
          Back
        </Button>
        {(setValues?.Status === 9 && setValues?.Created_By === show_integeration?.user_information?.Id && !disabled) && setValues?.Status !== 10 && setValues?.approvers_info?.length &&
          <Button
            variant='outlined'
            className={styles.approve_btn}
            style={{ borderColor: 'var(--primary)', textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
            onClick={() => handleApproval(setValues, 'edit')}
          >
            Edit
          </Button>}
        {setValues?.approvers_info?.length && disabled && setValues?.Status !== 1 && setValues?.Status !== 3 && setValues?.Status !== 10 &&
          <>
            {reject ? <Button
              variant='outlined'
              className={styles.approve_btn}
              style={{ borderColor: 'var(--primary)', textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
              onClick={() => handleApproval(setValues, 'edit')}
            >
              Edit
            </Button> :
              <Button
                variant='outlined'
                className={styles.approve_btn}
                style={{ borderColor: 'var(--primary)', textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
                onClick={() => handleApproval(setValues, 'approve')}
              >
                Approve
              </Button>}
          </>}

        {setValues?.Status === 10 && (approved || setValues?.Created_By === show_integeration?.user_information?.Id) &&
          <Button
            variant='outlined'
            className={styles.approve_btn}
            style={{ borderColor: 'var(--primary)', textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
            onClick={() => handleApproval(setValues, 'send')}
          >
            Send
          </Button>}

        <Button
          variant='outlined'
          className={styles.approve_btn}
          style={{ borderColor: 'var(--primary)', textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
          onClick={() => handleView(id, setValues?.Org_Id)}
        >
          View
        </Button>

        <Button
          sx={{
            '&:hover': {
              '& .custom_svg_color': {
                filter:
                  'invert(95%) sepia(5%) saturate(0%) hue-rotate(341deg) brightness(105%) contrast(104%) !important',
              },
            },
          }}
          variant='outlined'
          className={styles.download_btn}
          style={{ color: '#23ab94', borderColor: '#23ab94', textTransform: 'unset', fontFamily: 'var(--primary-font-family)', cursor: loading && 'not-allowed' }}
          onClick={() => { !loading && handleDownLoad() }}
        >
          Download
        </Button>

        {(admin || show_integeration?.user_permission[3]?.delete === 1) &&
          <Button
            variant='outlined'
            className={styles.delete_btn}
            style={{ color: '#ed4c78', borderColor: '#ed4c78', textTransform: 'unset', fontFamily: 'var(--primary-font-family)' }}
            startIcon={<DeleteIcon fontSize={'20px'} className={styles.DeleteIcon} color='#ed4c78' />}
            onClick={() => setShowModal(true)}
          >
            Delete
          </Button>}
        <DeleteModal
          showModal={showModal}
          closeModal={(val) => setShowModal(val)}
          handleDelete={() => onDelete(id, undefined, 10, 1)}
        />
      </Box>
      <Box className={styles.main_contact_wrap}>
        <Box className={styles.main_contact}>
          <Box className={styles.detailed_info}>Detailed Information</Box>
          <Box className={styles.detailed_wrapper}>
            <Box className={styles.detailed_body}>
              <Box className={styles.name}>{values?.Template_Name}</Box>
            </Box>

            <Box style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              {values?.map((val, idx) => {
                const mapKey = keys[idx];
                let date = '';
                if (mapKey === 'Created_Time' || mapKey === 'Modified_Time') {
                  date = DateFormat(setValues[mapKey]);
                }

                return (
                  val === 'Sign Journey' && !JSON.parse(show_integeration?.plans_info)[0]?.plan_flags?.SIGN_JOURNEY ? null :
                    <Box key={val} style={{ display: 'flex', alignItems: 'center', height: mapKey === 'Template_Name' && '20px' }}>
                      <Box className={styles.detail_wrapper}>
                        {val}
                      </Box>
                      <Box style={{ fontWeight: 400, fontSize: '14px', color: 'var(--table-font-color)' }}>
                        {mapKey === 'Created_Time' || mapKey === 'Modified_Time' ? (
                          date
                        ) : (
                          <SignDetails value={mapKey} />
                        )}
                      </Box>
                    </Box>
                );
              })}
            </Box>
          </Box>
          {toggleSignJourney && <Modal
            open={toggleSignJourney}
            sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
            disableAutoFocus={true}
            onClose={() => setToggleSignJourney(false)}
            closeAfterTransition
          >
            <Box className={styles.new_contact_wrap} style={{ height: journey_len >= 8 ? 'calc(100vh - 100px)' : null }}>
              <IconButton
                className={styles.close}
                onClick={() => setToggleSignJourney(false)}
              >
                <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
              </IconButton>

              <Box style={{ fontSize: '20px', fontWeight: '600', color: 'var(--header-color)' }}>Sign Journey</Box>
              <>
                <Box sx={{ overflow: 'auto', height: (setValues?.approver_journey && (Object.keys(JSON.parse(setValues?.approver_journey))?.length >= 2 && !JSON.parse(setValues?.Sign_Journey)?.journey)) || journey_len >= 8 ? 'calc(100vh - 200px)' : '100%' }} className={styles.recipient_body}>
                  {setValues?.approver_journey && Object?.keys(JSON.parse(setValues?.approver_journey))?.length ? Object.keys(JSON.parse(setValues?.approver_journey))?.map((list, idx) => (
                    <Box key={idx}>
                      <Box style={{ padding: '25px 15px 0px 0px', fontSize: '15px', fontWeight: 400, color: 'var(--table-font-color)' }}>
                        {list}
                      </Box>
                      {JSON.parse(setValues?.approver_journey)[list]?.journey?.map((item, i) => {
                        const showLine = JSON.parse(setValues?.approver_journey)[list]?.journey?.length !== i + 1;
                        return (
                          <Box style={{ position: 'relative' }} key={i}>
                            <CustomSteper showLine={showLine} />
                            <Box className={styles.top_text}>
                              <Typography variant="caption" style={{ color: approval_status[item?.status]?.color, fontSize: 13, fontFamily: 'var(--primary-font-family)' }}>{approval_status[item?.status]?.status}</Typography>
                              <Typography variant="caption" style={{ float: 'right', fontSize: 12, fontFamily: 'var(--primary-font-family)', color: 'var(--table-font-color)' }}>{moment(new Date(item?.created_time)).format('DD/MM/YYYY LT')}</Typography>
                            </Box>
                          </Box>
                        )
                      })}
                    </Box>
                  )) : null}
                  <>
                    {!JSON.parse(setValues?.Sign_Journey)?.journey && Object?.keys(JSON.parse(setValues?.Sign_Journey))?.length ? Object.keys(JSON.parse(setValues?.Sign_Journey))?.map((list, idx) => (
                      <Box key={idx}>
                        <Box style={{ padding: '25px 15px 0px 0px', fontSize: '15px', fontWeight: 400, color: 'var(--table-font-color)' }}>
                          {list}
                        </Box>
                        {setValues?.Sign_Journey && JSON.parse(JSON.parse(setValues?.Sign_Journey)[list])?.journey?.map((item, i) => {
                          const showLine = JSON.parse(JSON.parse(setValues?.Sign_Journey)[list])?.journey?.length !== i + 1;
                          return (
                            <Box style={{ position: 'relative' }} key={i}>
                              <CustomSteper showLine={showLine} />
                              <Box className={styles.top_text}>
                                <Typography variant="caption" style={{ color: signStatus[item?.status]?.color, fontSize: 13, fontFamily: 'var(--primary-font-family)' }}>{signStatus[item?.status]?.status}</Typography>
                                <Typography variant="caption" style={{ float: 'right', fontSize: 12, fontFamily: 'var(--primary-font-family)', color: 'var(--table-font-color)' }}>{moment(new Date(item?.created_time)).format('DD/MM/YYYY LT')}</Typography>
                              </Box>
                              <Box className={styles.bottom_text}>
                                <Typography className={styles.device_name} style={{ display: 'block' }} variant="caption">{os_type[item?.os - 1] + ' ' + browser_type[item?.browser - 1]}</Typography>
                                <Typography className={styles.device_name} variant="caption">{item?.ip ? `IP: ${item?.ip}` : ''}</Typography>
                              </Box>
                            </Box>
                          )
                        })}
                      </Box>
                    )) : null}
                  </>
                </Box>
              </>
            </Box>
          </Modal>}
        </Box>
      </Box>
      <DocumentViewModal file={file} setFile={(value) => setFile(value)} />
    </Box>
  );
};

export default SignDetails;


const CustomSteper = ({ showLine }) => {
  return (
    <Box style={{ height: "100%", position: 'absolute', width: 10 }}>
      {showLine &&
        <Box style={{ height: 'calc(100% + 20px)', borderLeft: '1px dashed var(--primary)', position: 'absolute', translate: '-50%', left: '50%  ', top: 6 }}></Box>}
      <Box style={{ height: 10, width: 10, borderRadius: '50%', backgroundColor: 'var(--primary)', position: 'absolute', top: 6 }}></Box>
    </Box>
  )
};

const RenameDocument = ({ setOpen, setEdit_btn, setValues, fetchSignForOthers, setShow, type, fetchSignMySelf }) => {
  const [docName, setDocName] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setDocName(setValues['Template_Name']);
  }, []);

  const handleChange = (value) => {
    setDocName(value);
  };

  const handleRename = () => {
    if (docName && setValues['Template_Name'] !== docName) {
      setValues['Template_Name'] = docName;
      let params = { org_id: setValues['Org_Id'], template_name: docName, sign_id: setValues['Id'] };
      dispatch(toggleLoader(true));
      let api_param = type === 1 ? process.env.REACT_APP_SIGNMYSELF : process.env.REACT_APP_SIGNFOROTHERS;
      axios.put(api_param, params, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          setShow(false);
          dispatch(toggleLoader(false));
          if (type === 1) {
            fetchSignMySelf();
          } else {
            fetchSignForOthers();
          }
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false))
        });
    } else {
      if (!docName) toast.error('Document name is requied');
    }
  };

  return (
    <Box className={styles.document_rename_input}>
      <TextField
        onChange={(e) => handleChange(e.target.value)}
        value={docName}
        sx={{
          fieldset: { border: "1px solid #fff" },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: 'var(--primary)'
            },
            fontSize: '14px',
            paddingLeft: '2px'
          },
        }}
        name='Document Name'
        variant='outlined'
        type='text'
      />
      <Box sx={{ display: 'flex', marginLeft: '15px' }}>
        <IconButton
          className={styles.close_btn}
          onClick={() => [setOpen(false), setEdit_btn(false)]}
        >
          <CloseIcon sx={{ fontSize: 15, "&:hover": { color: "#ed4c78" } }} />
        </IconButton>
        <IconButton
          className={styles.close_btn}
          onClick={() => handleRename()}
        >
          <CheckIcon sx={{ fontSize: 15, "&:hover": { color: "var(--success)" } }} />
        </IconButton>
      </Box>
    </Box>
  )
};